var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.offers
    ? _c("div", { staticClass: "offer-box" }, [
        _c("span", { staticClass: "offer-left" }, [
          _vm._v("Verfügbare Angebote")
        ]),
        _vm._v(" "),
        _c("div", { domProps: { innerHTML: _vm._s(_vm.getOfferText) } })
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }